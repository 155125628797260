export const werderData = {
    shortName: 'Bremen',
    teamGroupName: null,
    teamIconUrl:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/b/be/SV-Werder-Bremen-Logo.svg/681px-SV-Werder-Bremen-Logo.svg.png',
    teamId: 134,
    teamName: 'Werder Bremen',
    currentLeagueString: 'bl1',
};

export const carlData = {
    name: 'Carl Sander',
};

export const ligaData = {
    endpoint: 'https://www.openligadb.de/api',
};

export const storageKeyPrefix = 'sanders_krug_';
