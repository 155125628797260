export const config = {
    apiKey: 'AIzaSyD0CpGLZsgRVE65q6FM6KpWXimyFxcw3ec',
    authDomain: 'sander-s-krug.firebaseapp.com',
    projectId: 'sander-s-krug',
    storageBucket: 'sander-s-krug.appspot.com',
    messagingSenderId: '66809648134',
    appId: '1:66809648134:web:62b1aeb4caff6179121f87',
};

export const credentials = {
    email: 'sanderskrug@gmail.com',
    password: '12345678',
};
