export const colours = {
    black: 'black',
    green: 'green',
    greenyellow: 'greenyellow',
    grey: 'grey',
    red: 'red',
    werderGreen: '#73AD21',
    white: 'white',
    yellow: 'yellow',
};
